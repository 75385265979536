@charset "utf-8";

/* ====================== */
/* Reset   */
/* ====================== */
*,:after,:before{
    box-sizing: border-box !important;
}


