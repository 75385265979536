body {margin:0; padding:0; font-size: 14px; font-family: "Myriad Pro","Myriad Web","Tahoma","Helvetica","Arial",sans-serif;min-width: 1000px; }
img, table {border: 0; border-image-width:0}
html, body {overflow: hidden;}
.header {
  height: 47px;
  background: url(../images/header_bg.jpg) #3a607c no-repeat;
}

.header h1 {
  display: none
}

.header .user {
  color: #fff;
  display: block;
  text-align: right;
  font-size:20px;
  height: 47px;	
  line-height: 47px;
  padding-right: 15px
}

.header .user .bar {
  color: #aaa;
  margin: 0 6px;
  font-size: 12px;
  font-weight: 100
}
.contnets_Frame { position: fixed; top:47px; left: 0; right:0; bottom:0;    width: 100%;
    height: 100%; overflow: auto}
